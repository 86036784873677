import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from '../../components/Button'
import HomeBox from '../../components/HomeBox'
import InputField from '../../components/InputField'
import Modal from '../../components/Modal'
import { useApi } from '../../context/Api'
import routes from '../../routing/routes'
import { validatePassword } from '../../utils'

const NewPasswordForm = () => {
    const navigate = useNavigate()
    const [data, setData] = useState({
        password: '',
        passwordError: '',
        passwordConfirm: '',
        passwordConfirmError: '',
        token: '',
    })
    const { newPassword } = useApi()

    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (searchParams.get('token')) {
            setData({ ...data, token: searchParams.get('token')! })
        }
    }, [])

    return (
        <HomeBox title="Crea una nuova password">
            <form>
                <div className="mb-4">
                    <InputField
                        label="Password"
                        type="password"
                        error={data.passwordError}
                        value={data.password}
                        onChange={(e) => {
                            setData({
                                ...data,
                                password: e.target.value,
                                passwordError: '',
                            })
                        }}
                    />
                </div>
                <div className="mb-4">
                    <InputField
                        label="Conferma password"
                        type="password"
                        error={data.passwordConfirmError}
                        value={data.passwordConfirm}
                        onChange={(e) => {
                            setData({
                                ...data,
                                passwordConfirm: e.target.value,
                                passwordConfirmError: '',
                            })
                        }}
                    />
                </div>
                <div className="text-center">
                    <Button
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault()
                            let error = false
                            const newData = { ...data }
                            if (
                                !data.password ||
                                !validatePassword(data.password)
                            ) {
                                error = true
                                newData.passwordError =
                                    'La password deve contenere essere almeno 8 caratteri, contenere almeno una lettera maiuscola ed un numero.'
                            }
                            if (data.password != data.passwordConfirm) {
                                error = true

                                newData.passwordConfirmError =
                                    'Le password non coincidono.'
                            }
                            if (!error) {
                                newPassword(data.password, data.token).then(
                                    (res) => {
                                        if (res) {
                                            Swal.fire({
                                                title: 'Password cambiata correttamente',
                                                toast: true,
                                                position: 'bottom-right',
                                                showConfirmButton: false,
                                                showCloseButton: true,
                                                timer: 2000,
                                            })
                                        } else {
                                            Swal.fire({
                                                title: 'La mail utilizzata è scaduta!',
                                                text: 'Prova a richiedere un nuovo cambio password.',
                                            })
                                        }
                                        navigate(routes.home)
                                    },
                                )
                            } else {
                                setData(newData)
                            }
                        }}
                        color="primary"
                    >
                        Cambia password
                    </Button>
                </div>
            </form>
        </HomeBox>
    )
}

export default NewPasswordForm
